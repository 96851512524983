const english = {
	lang: "En",
	back: "Back",
	backDesc: "Return to state list",
	tazkirahDesc: [
		`"There is none amongst the Muslims who plants a tree or sows seeds,
	and then a bird, or a person or an animal eats from it,
	but is regarded as a charitable gift for him.
	"`,
		`tazkirah-en-2`,
		`tazkirah-en-3`,
		`tazkirah-en-4`,
		`tazkirah-en-5`,
	],
	prayerList: {
		// imsak: "Imsak",
		fajr: "Fajr",
		syuruk: "Syuruk",
		dhuhr: "Dhuhr",
		asr: "Asr",
		maghrib: "Maghrib",
		isha: "Isha",
	},
	close: "Close",
	settings: "Settings",
	locationSelector: "Select location",
	prayerTimeEstimate: "to",
	changeLang: "Change Language",
	changeSettings: "Change Settings",
	setDarkMode: "Set dark mode",
	setMinimal: "Set minimal mode",
	// setLocation: "Select Location",
	credit: "AA",
	subcredit: "coded in Malaysia",
	subcredit_extra: "Thank you frontliners #covid19Malaysia",
	enableNotification: "Enable notification",
	currentPrayerTime: "Current prayer time",
};

export default english;