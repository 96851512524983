import React from "react";

export const Footer = () => {
	return (
		< footer >
			<a href="//github.com/hackathononly/adhanv2"><b>AA</b></a> <span title="coded in Malaysia">Coded in Malaysia</span>
			{/* <a href="/#">{translate.credit}</a>
				<span title={translate.subcredit}> {translate.subcredit}</span>
				<span title={translate.subcredit_extra}>
					{" "}
					{translate.subcredit_extra}
				</span> */}
		</ footer>
	)
}
